import { axiosPut, axiosWithToken,axiosGetBlob } from "./restCallBase.service";
import { validateDates } from "../Helpers/validateDates";
import Swal from "sweetalert2";

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_AYSA;

export const routesGetAll = async ({ startDate, endDate, location,personName, newRowPerPage, newPage }) => {
    if(!validateDates( startDate, endDate )){
        Swal.fire('Fechas inválidas', '', 'warning');
        return false;
    }
    
    let params = '?';
    startDate && ( params += `StartDate=${ startDate }&` );
    endDate && ( params += `EndDate=${ endDate }&` );
    location && ( params += `LocationId=${ location }&` );
    personName && ( params += `personName=${ personName }&` );
    params += `PageSize=${ newRowPerPage }&Page=${ newPage }&`;

    const url = `${ baseUrl }/route${ params }`;
    return await axiosWithToken( url ) ;
}

export const routesGetOne = async ( id, data ) => {
    const url = `${ baseUrl }/route/details/${ id }`;
    return await axiosWithToken( url, data );
}

export const routesGetByItems = async ({ startDate, endDate, location,personName,tag,serialNumber,inventoryId, newRowPerPage, newPage}) => {
    let page = newPage ?? 1;
    if(!validateDates( startDate, endDate )){
        Swal.fire('Fechas inválidas', '', 'warning');
        return false;
    }
    let params = '?';
    startDate && ( params += `StartDate=${ startDate }&` );
    endDate && ( params += `EndDate=${ endDate }&` );
    location && ( params += `LocationId=${ location }&` );
    personName && ( params += `personName=${ personName }&` );
    tag && ( params += `tag=${ tag }&` );
    serialNumber && ( params += `serialNumber=${ serialNumber }&` );
    inventoryId && ( params += `inventoryId=${ inventoryId }&` );
    params += `PageSize=${ newRowPerPage }&Page=${ page }&`;

    const url = `${ baseUrl }/route/items${ params }`;
    return await axiosWithToken( url ) ;
}

export const RejectRoute = async (routeId) => {
    const url = `${ baseUrl }/route/rejected?id=${routeId}`
    return await axiosPut(url);
}

export const exportReport = async(routeId) => {
    const url = `${ baseUrl }/route/report/${routeId}/file`
    return await axiosGetBlob(url);
}
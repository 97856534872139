const NOT_SYNC = 0
const SYNC =1 
const FAILED = 2


const getItemName = (state) => {
    switch(state) {
        case NOT_SYNC: 
           return "Pendiente de sincronización"
        case SYNC:
            return "Sincronizado"
        case FAILED :
            return "Sincronización Fallida" 
        default :
            return "";
        
    }
}

export {NOT_SYNC,SYNC,FAILED,getItemName}
import React from 'react';
import { Link, useMatch, useResolvedPath, } from 'react-router-dom';

export const CustomLinkComponent = ({ page, disabled, classes }) => {
    let resolved = useResolvedPath(page);
    let match = useMatch({ path: resolved.pathname, end: true });
    let classStyles = `${ classes.Link } ${ disabled && classes.disabledLink } ${ match && classes.LinkActive }`;

    return (
        <Link to={ `/${ page }` } className={ classStyles }>
            { page }
        </Link>
    );
}
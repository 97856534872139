import React, { useContext } from 'react';
import { RoutesContext } from '../../Contexts/BuildContext';
import { TableGenericComponent } from '../../Components/TableGeneric/TableGenericComponent';

const columns = [ {label:'Estado',field:"status"},{label:'Ubicación',field:'locationName'}, {label :'Fecha de Relevamiento',field:'creationDate'}, {label:'Personal',field:'userName'} ];

export const RoutesTable = () => {
    const {
        routes,
        routesPerPage,
        count,
        page,
        handlePageChange,
        handleClick
    } = useContext( RoutesContext );
    return (
        <TableGenericComponent
            bar={ true }
            columns={ columns }
            rows={ routes }
            count={ count }
            rowsPerPage={ routesPerPage }
            page={ page }
            handleChange={ handlePageChange }
            handleClick={ handleClick }
        />
    )
}

import React from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Box } from '@mui/material';

const stylesColumns = {
    fontSize: '16px'
}

const stylesRows = {
    color: '#575757',
    fontSize: '14px',
    fontStyle: 'normal'
}

const createColumn = (value) => {
    return createCell(value, stylesColumns);
}

const createCell = (value, styles) => {
    return (
        <TableCell
            key={value}
            sx={{ ...styles }}
        >
            {value}
        </TableCell>
    )
}

export const TableGenericComponent = ({
    bar = false,
    displayPagination = true,
    columns,
    rows,
    count,
    rowsPerPage,
    page,
    handleChange = function () { },
    handleClick = function () { },
    hasActions
}) => {
    const handleChangePage = (value) => {
        handleChange(rowsPerPage, value);
    };

    const handleChangeRowsPerPage = (event) => {
        handleChange(parseInt(event.target.value), page);
    };

    const displayTable = rows.length ? 'block' : 'none';

    const drawableColumns = hasActions ? [...columns, { label: "Acciones" }] : columns

    return (
        <Box sx={{ display: displayTable }}>
            {bar && <hr style={{ border: '1px solid #000' }} />}
            <TableContainer size="small" aria-label="a dense table" sx={{ width: '100%' }}>
                <Table aria-label="dense table">
                    <TableHead>
                        <TableRow>
                            {drawableColumns.map((column) => (createColumn(column.label)))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#EDEBEC'
                                    },
                                }}
                                onClick={() => handleClick(row.id)}
                            >
                                {
                                    columns.map((column) => (
                                        <TableCell
                                            key={row.id + " " + column.label + " " + row[column.field]}
                                            sx={{ ...stylesRows }}
                                        >
                                            {row[column.field]}
                                        </TableCell>
                                    ))
                                }
                                <TableCell key={row.id + " Acciones"} sx={{ ...stylesRows }}>
                                    {
                                        row.actions ?
                                            row.actions.map(x =>
                                                x.html
                                            ) : null
                                    }
                                </TableCell>



                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {displayPagination &&
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e,value )=> handleChangePage(value)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }
        </Box>
    )
}

TableGenericComponent.propTypes = {
    bar: PropTypes.bool,
    displayPagination: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    handleChange: PropTypes.func,
    handleClick: PropTypes.func
}
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { Sync, CancelOutlined, Download } from '@mui/icons-material';

import { Grid, Typography, Button } from '@mui/material';
import { RoutesDetailsContext } from '../../Contexts/BuildContext';
import { DIFF, getName } from '../../Helpers/synchronizationStateHelper';

export const RoutesDatailsHeader = () => {
    const { route, rejectRoute, exportReportFile } = useContext(RoutesDetailsContext);

    const { locationName, creationDate } = route;

    return (
        <>
            <Grid container>
                <Grid item xs={2}>
                    <Typography color="text.secondary" sx={{ fontSize: 14, fontWeight: 'normal' }}>
                        Te sugerimos confirmar los datos antes de sincronizar
                    </Typography>
                </Grid>
                {
                    route.synchronization === DIFF ?
                        <>

                            <Grid item xs={2} style={{ paddingRight: 0 }}>
                                <Button fullWidth color="primary" sx={{
                                    textTransform: 'none',
                                    width: '100%',

                                }}>
                                    <Typography color="primary" sx={{
                                        fontSize: 17,
                                        fontWeight: 'normal',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '1%'
                                    }}>
                                        <Sync />Sincronizar
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button color="primary" onClick={() => rejectRoute()} fullWidth sx={{
                                    textTransform: 'none',
                                    width: '100%',
                                }} >
                                    <Typography color="primary" sx={{
                                        fontSize: 17,
                                        fontWeight: 'normal',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '1%'
                                    }}>
                                        <CancelOutlined />Desestimar
                                    </Typography>
                                </Button>
                            </Grid>

                        </>
                        : null
                }
                <Grid item xs={2}>
                    <Button color="primary" onClick={() => exportReportFile()} fullWidth sx={{
                        textTransform: 'none',
                        width: '100%',
                    }} >
                        <Typography color="primary" sx={{
                            fontSize: 17,
                            fontWeight: 'normal',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1%',
                            paddingLeft: '0px'
                        }}>
                            <Download />Exportar
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <br></br>
            <br></br>
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant="h5" color="text.secondary">
                        Ubicación
                    </Typography>
                    <Typography color="text.secondary">
                        {locationName}
                    </Typography>

                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h5" color="text.secondary">
                        Fecha
                    </Typography>
                    <Typography color="text.secondary">
                        {moment(creationDate).format('DD / MM / yyyy')}
                    </Typography>

                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h5" color="text.secondary">
                        Estado
                    </Typography>
                    <Typography color="text.secondary">
                        {getName(route.synchronization)}
                    </Typography>


                </Grid>
            </Grid>

        </>
    )
}
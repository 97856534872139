import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { QuerysContext } from './BuildContext';
import { routesGetByItems } from '../Services/restCallRoute.service';
import { trackPromise } from 'react-promise-tracker';
import moment from 'moment';
import { locationGetAll } from '../Services/restCallLocation.service';
import Swal from 'sweetalert2';

export const QuerysState = ({ children }) => {
    const initialFormValues = {
        startDate: '',
        endDate: '',
        location: '',
        personName: '',
        tag: '',
        serialNumber:'',
        inventoryId:''
    };

    const [ querysPerPage, setQuerysPerPage ] = useState( 10 );
    const [ page, setPage ] = useState( 0 );
    const [ querys, setQuerys ] = useState([]);
    const [ count, setCount ] = useState( 0 );
    const [ locations, setLocations ] = useState([]);
    const [ formValues, setFormValues ] = useState( initialFormValues );

    const handleFormValuesChange = ({ target }) => {
        const name = target.name;
        const value = name === 'tag' ? target.value.trim() : target.value;
        
        setFormValues((prevState) => {
            return {
                ...prevState,
                [ name ]: value
            }
        });
    }

    const handlePageChange = ( routesPerPage, page ) => {
        handleQueryItems( formValues, routesPerPage, page );
    }

    const handleQueryLocations = async () => {
        const result = await trackPromise( locationGetAll() );
        if(result){
            const { data } = result;
            setLocations(
                data.map(({ id, attributes }) => ({
                    id: id,
                    name: attributes.full_path
                }))
            );
        }
    }

    const handleQueryItems = async ( formValues, newRowPerPage, newPage ) => {
        let page = newPage +1;
        const data = await trackPromise( routesGetByItems({
            ...formValues,
            newRowPerPage,
            newPage:page
        }));
        if(data){
            const { records, metadata } = data;
            if(!records.length){
                Swal.fire('Sin Datos', '', 'warning');
            }
            setQuerys(
                records.map((x) => ({
                    ...x,
                    id: x.routeId + x.tag,
                    creationDate: x.creationDate? moment(x.creationDate).format("DD/MM/yyyy") : '',
                }))
            );
            setCount( metadata.totalCount );
            setQuerysPerPage( newRowPerPage );
            setPage( newPage );
        }
    }

    return (
        <QuerysContext.Provider value={{
            initialFormValues,
            formValues,
            querysPerPage,
            page,
            count,
            querys,
            locations,
            handleFormValuesChange,
            handlePageChange,
            handleQueryLocations,
            handleQueryItems
        }}>
            { children }
        </QuerysContext.Provider>
    );
}

QuerysState.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import {ArrowBack} from '@mui/icons-material';
import { AppContext } from '../../Contexts/BuildContext';
import { CustomLinkComponent } from '../CustomLink/CustomLinkComponent';
import logoRounded from '../../Resource/Img/logo_aysa_rounded.svg';
import logoCircle from '../../Resource/Img/logo_aysa_circle.svg';
import { styles } from '../GlobalTemplates/styles';
import { TooltipDark } from '../GlobalTemplates/componentsStyles';
import { useNavigate } from 'react-router-dom';


const pages = [
    { label: 'Relevamientos', disabled: false },
    { label: 'Consultas', disabled: false },
    { label: 'Portales', disabled: true },
    { label: 'Activos', disabled: true }
];
const settings = ['Cerrar Sesión'];

export const HeaderComponent = () => {
    const { tokenDecoded, handleToken } = useContext( AppContext );
    const {auth,displayName} = tokenDecoded;
    const [ anchorElNav, setAnchorElNav ] = useState( null );
    const [ anchorElUser, setAnchorElUser ] = useState( null );
    const navigate = useNavigate();


    const handleOpenNavMenu = ( event ) => {
        setAnchorElNav( event.currentTarget );
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser( event.currentTarget );
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav( null );
    };
    const handleCloseUserMenu = (setting) => {
        setAnchorElUser( null );
        if(setting === 'Cerrar Sesión'){
            handleToken( '' );
        }
    };
    const classes = styles();

    const displayLogo = auth() ? { xs: 'none', md: 'flex' } : 'flex';
    const displayMenuNav = auth() ? { xs: 'flex', md: 'none' } : 'none';
    const displayMenuPages = auth() ? { xs: 'none', md: 'flex' } : 'none';
    const displayMenuUser = auth() ? 'flex' : 'none';

    return (
        <div className={ classes.appBar }>
            <AppBar position="static" color="secondary">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Grid container spacing={3}>
                            <Grid container item xs={ 1 } alignItems="stretch" justifyContent="flex-end">
                                <Box sx={{  display: displayLogo}}>
                                <Button color="primary" onClick={() => navigate(-1)} fullWidth sx={{
                                    textTransform: 'none',
                                    width: '20%',
                                }} >
                                    <Typography>
                                        <ArrowBack/>
                                    </Typography>
                                </Button>
                                    <Link to="/">
                                        <Avatar
                                            sx={{ my: 2 }}
                                            variant="rounded"
                                            alt="Logo Rounded"
                                            src={ logoRounded }
                                        />
                                    </Link>
                                </Box>
                                <Box sx={{ my: 1.5, display: displayMenuNav }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                        }}
                                        className={ classes.menuPaper }
                                    >
                                    { pages.map(({ label, disabled }) => (
                                        <MenuItem key={ label } onClick={ handleCloseNavMenu } disabled={ disabled || !tokenDecoded.isAllowed }>
                                            <Typography key={ label } textAlign="center">
                                                <CustomLinkComponent page={ label } disabled={ disabled || !tokenDecoded.isAllowed  } classes={ classes } />
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                    </Menu>
                                </Box>
                            </Grid>
                            <Grid item xs={ 8 }>
                                <Box sx={{ display: displayMenuNav, justifyContent: 'center' }}>
                                    <Link to="/">
                                        <Avatar
                                            sx={{ my: 2 }}
                                            variant="rounded"
                                            alt="Logo Rounded"
                                            src={ logoRounded }
                                        />
                                    </Link>
                                </Box>
                                <Box sx={{ display: displayMenuPages }}>
                                    {pages.map(({ label, disabled }) => (
                                        <Typography
                                            key={label}
                                            onClick={handleCloseNavMenu}
                                            sx={{ my: 3, mx: 2, display: 'block' }}
                                            disabled={ disabled || !tokenDecoded.isAllowed  }
                                        >
                                            <CustomLinkComponent page={ label } disabled={ disabled || !tokenDecoded.isAllowed  } classes={ classes } />
                                        </Typography>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={ 3 } sx={{ my: 2, display: displayMenuUser }}>
                                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                    <Grid item>
                                        <TooltipDark title="Abrir Opciones">
                                            <Button onClick={ handleOpenUserMenu } sx={{ p: 0 }} color="primary" className={ classes.userButton }>{ displayName }</Button>
                                        </TooltipDark>
                                        <Menu
                                            sx={{ mt: '45px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                            className={ classes.menuPaper }
                                        >
                                            {settings.map((setting) => (
                                                <MenuItem key={ setting } onClick={ () => { handleCloseUserMenu( setting ) } }>
                                                    <Typography textAlign="center">{ setting }</Typography>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Grid>
                                    <Grid item>
                                        <Avatar alt="Logo Circle" src={logoCircle} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
};
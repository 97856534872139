import React, { useContext } from 'react';

import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes
} from 'react-router-dom';

import { Typography,Alert } from '@mui/material';

import jwtDecode from 'jwt-decode';
import { AppContext } from '../Contexts/BuildContext';
import { PublicRoutes } from './PublicRoutes';
import { PrivateRoutes } from './PrivateRoutes';

import { FooterComponent } from '../Components/Footer/FooterComponent';
import { HeaderComponent } from '../Components/Header/HeaderComponent';

import mainImg from '../Resource/Img/Main.png';

import { LoginPage } from '../Pages/LoginPage';
import { RoutesPage } from '../Pages/RoutesPage/RoutesPage';
import { RoutesDetailsPage } from '../Pages/RoutesDetailsPage/RoutesDetailsPage';
import { QuerysPage } from '../Pages/QuerysPage/QuerysPage';
import { Container } from '../Components/GlobalTemplates/componentsStyles';
import { styles } from '../Components/GlobalTemplates/styles';

export const AppRoutes = () => {
    const { tokenDecoded } = useContext(AppContext);
    const { auth, displayName, isAllowed } = tokenDecoded

    const classes = styles();

    return (
        <Container>
            <Router>
                <HeaderComponent />

                <div>
                    <Routes>
                        <Route path="/login" element={<PublicRoutes auth={auth()} />}>
                            <Route index element={
                                <>
                                    <LoginPage />
                                    <img className={classes.mainImg} src={mainImg} alt="main" />
                                </>
                            } />
                        </Route>

                        <Route path="/" element={<PrivateRoutes auth={auth()} />}>
                            <Route index element={
                                <>
                                    {
                                        !isAllowed &&
                                        <Alert severity="warning">El perfil de usuario no es válido para esta aplicación. Contactese con Administración para resolver el acceso a las funcionalidades</Alert>
                                    }
                                    <Typography variant="h2" className={classes.mainTitle}>
                                        Bienvenido, {displayName}
                                    </Typography>
                                    <img className={classes.mainImg} src={mainImg} alt="main" />
                                </>
                            } />

                            <Route exact path="/consultas" element={
                                <QuerysPage />
                            } />

                            <Route exact path="/relevamientos" element={
                                <RoutesPage />
                            } />

                            <Route path="/relevamientos/id=:id" element={
                                <RoutesDetailsPage />
                            } />
                        </Route>

                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
                <FooterComponent />
            </Router>
        </Container>
    )
}

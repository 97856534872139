import React from 'react';
import AppState from './Contexts/AppState'
import { MainPage } from './Pages/MainPage';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <AppState>
        <MainPage />
      </AppState>
    </SnackbarProvider>
  );
}

export default App;

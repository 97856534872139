import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { trackPromise } from 'react-promise-tracker';
import PropTypes from 'prop-types';
import moment from 'moment';
import Swal from 'sweetalert2';
import { RoutesContext } from './BuildContext';
import { routesGetAll } from '../Services/restCallRoute.service';
import { locationGetAll } from '../Services/restCallLocation.service';
import { getStatus } from '../Helpers/synchronizationStateHelper';


export const RoutesState = ({ children }) => {
    const initialFormValues = {
        startDate: '',
        endDate: '',
        location: '',
        personName: ''
    };

    const [ routesPerPage, setRoutesPerPage ] = useState( 10 );
    const [ page, setPage ] = useState( 0 );
    const [ routes, setRoutes ] = useState([]);
    const [ count, setCount ] = useState( 0 );
    const [ locations, setLocations ] = useState([]);
    const [ formValues, setFormValues ] = useState( initialFormValues );

    const navigate = useNavigate();

    const handleFormValuesChange = ({ target }) => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                [ target.name ]: target.value
            }
        });
    }

    const handlePageChange = ( routesPerPage, page ) => {
        handleQueryRoutes( formValues, routesPerPage, page );
    }

    const handleClick = ( routeId ) => {
        navigate(`/relevamientos/id=${ routeId }`);
    }

    const handleQueryRoutes = async ( formValues, newRowPerPage, newPage ) => {
        let page = newPage + 1
        const data = await trackPromise( routesGetAll({ ...formValues, newRowPerPage, newPage:page }) );
        if(data){
            const { records, metadata } = data;
            if(!records.length){
                Swal.fire('Sin Datos', '', 'warning');
            }
            setRoutes(
                records.map( x => (
                    {
                        ...x,
                        status: getStatus(x.synchronization),
                        creationDate: moment(x.creationDate).format("DD/MM/yyyy"),
                    }
                ))
            );
            setCount( metadata.totalCount );
            setRoutesPerPage( newRowPerPage );
            setPage( newPage );
        }
    }

    const handleQueryLocations = async () => {
        const result = await trackPromise( locationGetAll() );
        if(result){
            const { data } = result;
            setLocations(
                data.map(({ id, attributes }) => ({
                    id: id,
                    name: attributes.full_path
                }))
            );
        }
    }
    return (
        <RoutesContext.Provider value={{
            formValues,
            routes,
            routesPerPage,
            page,
            count,
            locations,
            handleFormValuesChange,
            handleQueryRoutes,
            handlePageChange,
            handleQueryLocations,
            handleClick
        }}>
            { children }
        </RoutesContext.Provider>
    );
}

RoutesState.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}
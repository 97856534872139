import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { usePromiseTracker } from "react-promise-tracker";
import { ClipLoader } from 'react-spinners';

export default function SpinnerComponent() {
    const { promiseInProgress } = usePromiseTracker();

    return (

        <Box style={{ flex: 1, zIndex: 1000 }}>
            <ClipLoader size = '120px' loading={promiseInProgress} color='#4A90E2'></ClipLoader>
        </Box>

    );
}
import React, { useContext } from 'react';
import { Box } from '@mui/system';
import { RoutesDetailsContext } from '../../Contexts/BuildContext';
import { AccordionComponent } from '../../Components/Accordion/AccordionComponent';



const columnsMatch = [ {label:'N° serie',field:"serialNumber"},{label:'Código inventario',field:'inventoryId'}, {label :'Tag',field:'tag'},{label:'Estado',field:'itemState'},{label:'Responsable',field:"owner"},{label:'Ultima Actualización',field:"lastUpdate"} ];
const columnsOnlyOnInsight = [ {label:'N° serie',field:"serialNumber"},{label:'Código inventario',field:'inventoryId'},{label:'Responsable',field:"owner"},{label:'Ultima Actualización',field:"lastUpdate"} ];
const columnsWithNoLocation = [ {label:'N° serie',field:"serialNumber"},{label:'Código inventario',field:'inventoryId'}, {label :'Tag',field:'tag'}, {label:'Responsable',field:'owner'},,{label:'Ultima Actualización',field:"lastUpdate"}  ];
const columnsNotFoundInInsight = [ {label:'N° serie',field:"serialNumber"},{label:'Código inventario',field:'inventoryId'}, {label :'Tag',field:'tag'}, {label:'Responsable',field:'owner'} ];
const columnsWithOtherLocation = [ {label:'N° serie',field:"serialNumber"},{label:'Código inventario',field:'inventoryId'}, {label :'Tag',field:'tag'}, {label:'Responsable',field:'owner'},{label:'Ubicación',field:'locationNameInsight'} ];
const columnsWithNoLink = [{label :'Tag',field:'tag'}];

export const RoutesDetailsAccordionList = () => {
    const {
        match,
        withNoLocation,
        notFoundInInsight,
        withOtherLocation,
        withNoLink,
        onlyOnInsight,
        route
        
    } = useContext( RoutesDetailsContext );
    return (
        <Box>
            <AccordionComponent
                columns={ columnsMatch }
                rows={ match }
                summary="Match"
                hasActions={true}
            />
            {
               route.report &&
               <AccordionComponent
               columns={ columnsOnlyOnInsight }
               rows={ onlyOnInsight }
               summary="Activos faltantes en la ubicación"
           />
            }

            <AccordionComponent
                columns={ columnsWithNoLocation }
                rows={ withNoLocation }
                summary="Activos sin Ubicación definida en sistema de inventario"
            />

            <AccordionComponent
                columns={ columnsNotFoundInInsight }
                rows={ notFoundInInsight }
                summary="Items no encontrados en Sistema Inventario"
            />

            <AccordionComponent
                columns={ columnsWithOtherLocation }
                rows={ withOtherLocation }
                summary="Items con otra ubicación en sistema inventario"
            />
            
            <AccordionComponent
                columns={ columnsWithNoLink }
                rows={ withNoLink }
                summary="Items sin Vinculación"
            />
        </Box>
    )
}
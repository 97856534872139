import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { AppRoutes } from '../Routes/AppRoutes';
import { themeGlobal as theme } from '../Components/GlobalTemplates/themeGlobal';
import SpinnerComponent from '../Components/Spinner/SpinnerComponent';
import { styles } from '../Components/GlobalTemplates/styles';
import { usePromiseTracker } from "react-promise-tracker";


export const MainPage = () => {
    const classes = styles();
    const { promiseInProgress } = usePromiseTracker();

    return (
        <ThemeProvider theme={theme}>
            <div className={promiseInProgress ? classes.parentDisable : ''} >
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }}>
                    <SpinnerComponent />
                </div>
                <AppRoutes />
            </div>
        </ThemeProvider>
    );
};



import { Check, Sync,WarningOutlined,DangerousOutlined } from '@mui/icons-material';
import { Grid } from '@material-ui/core';

const DIFF = 2
const REJECTED =3 
const SENT = 4
const SENT_WITH_ERRORS = 5


const getStatus = (state) => {
    switch(state) {
        case DIFF: 
           return <Grid container direction="row" alignItems="center"> <Sync />Pendiente de sincronización </Grid>
        case REJECTED:
            return <Grid container direction="row" alignItems="center"> <DangerousOutlined/>Desestimado</Grid>
        case SENT :
            return <Grid container direction="row" alignItems="center"> <Check/>Sincronizado </Grid>
        case SENT_WITH_ERRORS :
            return <Grid container direction="row" alignItems="center"> <WarningOutlined/>Sincronizado con errores </Grid>
        default :
            return "";
        
    }
}

const getName = (state) => {
    switch(state) {
        case DIFF: 
           return "Pendiente de sincronización"
        case REJECTED:
            return "Desestimado"
        case SENT :
            return "Sincronizado" 
        case SENT_WITH_ERRORS :
            return "Sincronizado con errores"
        default :
            return "";
        
    }
}

export {DIFF,REJECTED,SENT,SENT_WITH_ERRORS,getStatus,getName}
import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { RoutesDetailsContext } from './BuildContext';
import { routesGetOne } from '../Services/restCallRoute.service';
import { RejectRoute,exportReport } from '../Services/restCallRoute.service';
import { trackPromise } from 'react-promise-tracker';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Sync } from '@mui/icons-material';
import { getItemName, FAILED } from '../Helpers/itemSynchronizationStateHelper';
import fileDownload from 'js-file-download'

export const RoutesDetailsState = ({ children }) => {
    const { id } = useParams();
    const routeId = id;
    const { enqueueSnackbar } = useSnackbar();
    const initialState = {
        route: {},
        match: [],
        withNoLocation: [],
        notFoundInInsight: [],
        withOtherLocation: [],
        withNoLink: [],
        onlyOnInsight:[]
    };
   
    const [ state, setState ] = useState( initialState );

    const handleQueryRoutesDetails = async () => {
        const data = await trackPromise( routesGetOne( routeId ) );
        if(data){
            const match = data.match.map(x => ({
                ...x,
                lastUpdate:  moment(x.lastUpdate).format("DD/MM/yyyy"),
                itemState : getItemName(x.synchronized),
                actions : x.synchronized === FAILED ? [{html: <Sync />,name:"Reintentar" }] :null
            }));
    
           const withNoLocation = data.withNoLocation.map(x => ({
                ...x,
                lastUpdate:  moment(x.lastUpdate).format("DD/MM/yyyy")
            }));
    
            const notFoundInInsight = data.notFoundInInsight
    
            const withOtherLocation = data.withOtherLocation.map(x => ({
                ...x,
                lastUpdate:  moment(x.lastUpdate).format("DD/MM/yyyy")                
            }));
    
            const withNoLink = data.withNoLink.map(({ id, tag }) => ({
                id: id,
                tag: tag
            }));
            const onlyOnInsight = data.onInsight.map(x => ({
                ...x,
                lastUpdate:  moment(x.lastUpdate).format("DD/MM/yyyy")
            }));

            setState({
                ...state,
                route: data.route,
                match,
                withNoLocation,
                notFoundInInsight,
                withOtherLocation,
                withNoLink,
                onlyOnInsight
            });
        }
    }

    const rejectRoute = async() => {
        const data = await trackPromise( RejectRoute( routeId ) );
        setState({
            ...state,
            route : data.route
        });
        enqueueSnackbar("Se actualizó el estado con éxito",{ 
            variant: 'success',
        })   
    }

    const exportReportFile = async () => {
        const file = await trackPromise(exportReport(routeId));
        fileDownload(file, "Informe Relevamiento " + moment(state.route.creationDate).format("DD/MM/yyyy")+".xls");
    }


    return (
        <RoutesDetailsContext.Provider value={{
            route: state.route,
            match: state.match,
            withNoLocation: state.withNoLocation,
            notFoundInInsight: state.notFoundInInsight,
            withOtherLocation: state.withOtherLocation,
            withNoLink: state.withNoLink,
            onlyOnInsight: state.onlyOnInsight,
            handleQueryRoutesDetails,
            rejectRoute,
            exportReportFile
        }}>
            { children }
        </RoutesDetailsContext.Provider>
    );
}


RoutesDetailsState.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}
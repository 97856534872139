import React, { useContext, useEffect } from 'react'
import { usePromiseTracker } from "react-promise-tracker";

import { Box } from '@mui/system';
import { Container, Typography, Card, CardContent } from '@mui/material';

import { RoutesDetailsContext } from '../../Contexts/BuildContext';
import { RoutesDetailsState } from '../../Contexts/RoutesDetailsState';
import { RoutesDatailsHeader } from './RoutesDatailsHeader';
import { RoutesDetailsAccordionList } from './RoutesDetailsAccordionList';
import { CircularIndeterminate } from '../../Components/Progress/ProgressComponent';


const RoutesDetails = () => {
    const { handleQueryRoutesDetails } = useContext( RoutesDetailsContext );
    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => {
        handleQueryRoutesDetails();
    }, []);

    return (
        <Container>
            <Box sx={{ padding: '5% 3% 0' }}>
                <Typography variant="h4" sx={{ fontWeight: 700 }} paddingBottom={ 4 }>Relevamiento</Typography>
            </Box>
            {
                !promiseInProgress &&( 
                
                <Card sx={{ minWidth: 275, border: '1px solid rgba(0, 0, 0, 0.25)', boxShadow: 'none' }}>
                    <CardContent>
                        <Box sx={{ padding: '1% 3%' }}>
                            <RoutesDatailsHeader />
                        </Box>
                        <Box sx={{ padding: '1% 3%' }}>
                            <RoutesDetailsAccordionList />
                        </Box>
                    </CardContent>
                </Card>
                )
            }
        </Container>
    )
}

export const RoutesDetailsPage = () => {
    return (
        <RoutesDetailsState>
            <RoutesDetails />
        </RoutesDetailsState>
    );
}
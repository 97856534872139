import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from './BuildContext';
import { login } from '../Services/restCallAuth.service';
import jwtDecode from 'jwt-decode';


const initialDecodedToken = {displayName:'',isAllowed:false,auth:() => false,exp:0}

const AppState = ({ children }) => {

    const decodeToken = (token) => {
        if (!token) {
            return initialDecodedToken;
        }
        let decodedToken = jwtDecode(token);
        return { ...decodedToken,displayName : decodedToken.DisplayName, isAllowed: decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"].filter(x => x === "Auditor").length > 0,auth : ()=> (Date.now() < decodedToken.exp * 1000) }
    }

    const initialState = {
        token: localStorage.getItem('token') || '',
        tokenDecoded: decodeToken(localStorage.getItem('token'))
    };

    const [state, setState] = useState(initialState);

    const handleToken = (newToken) => {
        setState({ token: newToken, tokenDecoded: newToken ? decodeToken(newToken): initialDecodedToken});
        localStorage.setItem('token', newToken);
    }


    return (
        <AppContext.Provider value={{
            token: state.token,
            tokenDecoded: state.tokenDecoded,
            handleToken: handleToken,
            login: login
        }}>
            {children}
        </AppContext.Provider>
    )
}
export default AppState;

AppState.propTypes = {
    children: PropTypes.element
}
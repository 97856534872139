import React, { useContext } from 'react';
import { QuerysContext } from '../../Contexts/BuildContext';
import { TableGenericComponent } from '../../Components/TableGeneric/TableGenericComponent';

const columns = [ {label:'TAG RFID',field:'tag'}, {label:'N° serie', field:'serialNumber'},{label:'Código Inventario', field:'inventoryId'} , {label:'Ubicación',field:'locationName'},{label:"Detectado por",field:"personName"},{label:'Fecha Match',field:'creationDate'} ];

export const QuerysTable = () => {
    const {
        querys,
        count,
        querysPerPage,
        page,
        handlePageChange
    } = useContext( QuerysContext );
    
    return (
        <TableGenericComponent
            bar={ true }
            columns={ columns }
            rows={ querys }
            count={ count }
            rowsPerPage={ querysPerPage }
            page={ page }
            handleChange={ handlePageChange }
        />
    )
}
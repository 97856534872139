import React, { useContext, useEffect } from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import { Box } from '@mui/system';
import { FormHelperText, Grid, MenuItem, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styles } from '../../Components/GlobalTemplates/styles';
import { QuerysContext } from '../../Contexts/BuildContext';

export const QuerysFilter = () => {
    const {
        initialFormValues,
        formValues,
        handleFormValuesChange,
        handleQueryItems,
        querysPerPage,
        page,
        locations,
        handleQueryLocations
    } = useContext(QuerysContext);

    const {
        startDate,
        endDate,
        location,
        personName,
        tag,
        serialNumber,
        inventoryId
    } = formValues;

    useEffect(() => {
        handleQueryLocations();
    }, []);

    const { promiseInProgress } = usePromiseTracker();

    const classes = styles();
    return (
        <Box>
            <Grid container spacing={2} alignItems={"flex-end"}>
                <Grid item xs={3}>
                    <FormHelperText id="startDate-helper-text">Desde el</FormHelperText>
                    <TextField
                        id="startDate"
                        name="startDate"
                        type="date"
                        fullWidth
                        value={startDate}
                        onChange={handleFormValuesChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormHelperText id="endDate-helper-text">Hasta el</FormHelperText>
                    <TextField
                        id="endDate"
                        name="endDate"
                        type="date"
                        fullWidth
                        value={endDate}
                        onChange={handleFormValuesChange}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        id="location"
                        name="location"
                        select
                        label="Ubicación"
                        value={location}
                        onChange={handleFormValuesChange}
                    >
                        {
                            locations.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        id="personal"
                        name="personName"
                        label="Personal"
                        value={personName}
                        onChange={handleFormValuesChange}
                    />
                </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        id="tag"
                        name="tag"
                        label="TAG"
                        fullWidth
                        value={tag}
                        onChange={handleFormValuesChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="serialNumber"
                        name="serialNumber"
                        label="N° serie"
                        fullWidth
                        value={serialNumber}
                        onChange={handleFormValuesChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id="inventoryId"
                        name="inventoryId"
                        label="Código inventario"
                        fullWidth
                        value={inventoryId}
                        onChange={handleFormValuesChange}
                    />
                </Grid>
            </Grid>
            <Box sx={{ margin: '2% 0' }}>
                <Button
                    variant="outlined"
                    className={classes.relevamientosButton}
                    disabled={JSON.stringify(initialFormValues) === JSON.stringify(formValues)}
                    onClick={() => handleQueryItems(formValues, querysPerPage, page, location)}
                    sx={{
                        backgroundColor: 'white',
                        border: '1px solid #252F80',
                        color: '#252F80',
                        '&:hover': {
                            backgroundColor: '#252F80',
                            color: 'white',
                            border: 'none'
                        }
                    }}
                >
                    Consultar
                </Button>
            </Box>
        </Box>
    )
}
import moment from "moment";

export const validateDates = ( startDate, endDate ) => {
    if (!startDate && !endDate)
       return true;
    if ((startDate && !moment(startDate).isValid()) ||
    (endDate && !moment(endDate).isValid()) || (startDate && endDate && moment(startDate).isAfter(moment(endDate))))
    {
      return false;
    }
    return true
}